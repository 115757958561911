import { ContainerBoxInterface } from '@peakconcepts/landingpage-kit'
import ctaButton from './ctaButtonBewertung'
import colors from '../default/colors'

const itemDescriptionConfig = {
  defaultCss: {
    textAlign: 'left'
  },
  margin: {
    desktop: '0 0 20px',
    tablet: '0 0 20px',
    mobile: '0 0 20px'
  }
}

const itemIconConfig = {
  size: 7,
  icon: 'done',
  defaultCss: {
    color: 'primary'
  }
}

const itemBadeClassName = 'column is-6 pt-0 pl-0 pr-0'

const itemBadgeConfig = {
  width: {
    desktop: '80%',
    tablet: '80%',
    mobile: '80%'
  },
  margin: {
    desktop: '0 10%',
    tablet: '0 10%',
    mobile: '0 10%'
  }
}

const data: ContainerBoxInterface = {
  defaultCss: {
    background: 'url("/images/hero.jpg")',
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  },
  containers: [
    {
      defaultCss: {
        backgroundColor: 'rgba(232, 238, 249, .96)'
      },
      containers: [
        {
          classNames:
            'is-hidden-mobile is-hidden-tablet-only is-hidden-desktop-only',
          defaultCss: {
            position: 'absolute',
            right: '0',
            top: '130px'
          },
          containers: [
            {
              type: 'image',
              config: {
                src: '/images/badges/focus.jpg',
                width: {
                  desktop: '120px'
                }
              }
            }
          ]
        },
        {
          classNames: 'container',
          padding: {
            fullHd: '120px 0 80px',
            widescreen: '120px 0 80px',
            desktop: '120px 40px 80px',
            tablet: '80px 40px',
            mobile: '70px 20px 20px'
          },
          containers: [
            {
              classNames: 'columns is-multiline',
              containers: [
                {
                  classNames: 'column is-full',
                  containers: [
                    {
                      type: 'text',
                      config: {
                        id: 'heroHeaderTitleId',
                        defaultCss: {
                          textAlign: 'center'
                        },
                        fontSize: {
                          desktop: '18px',
                          tablet: '14px',
                          mobile: '12px'
                        },
                        margin: {
                          desktop: '0 0 15px',
                          tablet: '0 0 5px',
                          mobile: '0 10px 20px'
                        },
                        text:
                          '<b><u>Wir verkaufen Ihr Haus oder Ihre Eigentumswohnung zum TOP-Preis:</u></b>'
                      }
                    },
                    {
                      type: 'text',
                      config: {
                        id: 'heroHeaderDescriptionId',
                        size: 2,
                        margin: {
                          fullHd: '0 100px 30px',
                          widescreen: '0 100px 30px',
                          desktop: '0 0 30px',
                          tablet: '0',
                          mobile: '0 10px'
                        },
                        fontSize: {
                          desktop: '32px',
                          tablet: '28px',
                          mobile: '18px'
                        },
                        text:
                          'Erfahren Sie jetzt, wie viel Ihre Immobilie <b><u>wirklich</u></b> wert ist und erhalten Sie in kurzer Zeit einen Verkaufspreis von <b><u>bis zu 30 % über Marktwert</u></b>'
                      }
                    },
                    {
                      type: 'hr',
                      config: {
                        height: 4,
                        width: '150px',
                        defaultCss: {
                          color: 'primary'
                        },
                        margin: {
                          desktop: '30px auto 10px',
                          tablet: '30px auto 0',
                          mobile: '20px auto 0'
                        }
                      }
                    }
                  ]
                },
                {
                  classNames:
                    'column is-12-mobile is-12-tablet is-12-desktop is-6-widescreen',
                  containers: [
                    {
                      type: 'video',
                      config: {
                        url: 'https://vimeo.com/530669135',
                        previewUrl: '/videos/preview.mp4',
                        color: colors.primary
                      }
                    },
                    {
                      type: 'cta',
                      config: {
                        classNames: 'is-hidden-tablet',
                        dark: false,
                        margin: {
                          desktop: '30px 0 0',
                          tablet: '30px 0 0',
                          mobile: '30px 0 0'
                        },
                        ...ctaButton
                      }
                    }
                  ]
                },
                {
                  classNames:
                    'column is-12-mobile is-12-tablet is-12-desktop is-6-widescreen',
                  containers: [
                    {
                      type: 'textWithIcon',
                      config: {
                        descriptionConfig: {
                          text:
                            '<b>Mehr Sicherheit:</b> Wir ermitteln den aktuellen Verkaufswert Ihrer Immobilie, sodass Sie Ihr Objekt nicht unter Wert verkaufen',
                          ...itemDescriptionConfig
                        },
                        iconConfig: {
                          ...itemIconConfig
                        }
                      }
                    },
                    {
                      type: 'textWithIcon',
                      config: {
                        descriptionConfig: {
                          text:
                            '<b>Verkaufen Sie bis zu 30 % über Marktwert:</b> Wir setzen Ihre Immobilie gekonnt in Szene und überzeugen liquide Käufer von höheren Verkaufspreisen',
                          ...itemDescriptionConfig
                        },
                        iconConfig: {
                          ...itemIconConfig
                        }
                      }
                    },
                    {
                      type: 'textWithIcon',
                      config: {
                        descriptionConfig: {
                          text:
                            '<b>Geringer Aufwand:</b> Wir halten Ihnen den Rücken frei und übernehmen die ganze Verwaltung für Sie!  ',
                          ...itemDescriptionConfig
                        },
                        iconConfig: {
                          ...itemIconConfig
                        }
                      }
                    },
                    {
                      type: 'cta',
                      config: {
                        dark: false,
                        classNames: 'is-hidden-mobile',
                        margin: {
                          desktop: '10px 0 0',
                          tablet: '10px 0 0',
                          mobile: '10px 0 0'
                        },
                        ...ctaButton
                      }
                    },
                    {
                      containers: [
                        {
                          classNames: 'columns is-multiline is-hidden-tablet',
                          containers: [
                            {
                              classNames: 'column is-full pb-0',
                              containers: [
                                {
                                  type: 'text',
                                  config: {
                                    text: '<b>Ausgezeichnet & bekannt aus:</b>',
                                    defaultCss: {
                                      textAlign: 'center'
                                    },
                                    fontSize: {
                                      desktop: '14px',
                                      tablet: '14px',
                                      mobile: '12px'
                                    },
                                    margin: {
                                      desktop: '20px 0 0',
                                      tablet: '20px 0 0',
                                      mobile: '20px 0 0'
                                    }
                                  }
                                }
                              ]
                            },
                            {
                              classNames: 'column is-full pb-0',
                              containers: [
                                {
                                  classNames:
                                    'columns is-multiline is-mobile is-marginless',
                                  containers: [
                                    {
                                      classNames: itemBadeClassName,
                                      containers: [
                                        {
                                          type: 'image',
                                          config: {
                                            src: '/images/badges/faz.jpg',
                                            ...itemBadgeConfig
                                          }
                                        }
                                      ]
                                    },
                                    {
                                      classNames: itemBadeClassName,
                                      containers: [
                                        {
                                          type: 'image',
                                          config: {
                                            src: '/images/badges/focus.jpg',
                                            ...itemBadgeConfig
                                          }
                                        }
                                      ]
                                    }
                                  ]
                                }
                              ]
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
}

export default data
