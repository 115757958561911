import set from 'lodash/set'
import cloneDeep from 'lodash/cloneDeep'
import findDeep from 'deepdash-es/findDeep'
import seoHeaderData from '../data/seoHeader'
import homeSections from '../sections/home'

const sections = cloneDeep(homeSections)
const headerData = sections.find((section: any) => section.id === 'heroData')

if (headerData) {
  // Title
  const title = findDeep(
    headerData.data,
    (value, key) => key === 'id' && value === 'heroHeaderTitleId'
  )
  if (title?.parent) {
    set(
      title.parent,
      'text',
      '<b><u>Wir verkaufen Ihr Haus zum TOP-Preis:</u></b>'
    )
  }

  // Description
  const description = findDeep(
    headerData.data,
    (value, key) => key === 'id' && value === 'heroHeaderDescriptionId'
  )
  if (description?.parent) {
    set(
      description.parent,
      'text',
      'Erfahren Sie jetzt, wie viel Ihr Haus <b><u>wirklich</u></b> wert ist und erhalten Sie in kurzer Zeit einen Verkaufspreis von <b><u>bis zu 30 % über Marktwert</u></b>'
    )
  }
}

export const data = {
  path: '/haus-verkaufen',
  headerEnabled: true,
  seoHeaderConfig: seoHeaderData.main,
  sections
}

export default data
