// Defaults
import { Content } from '@peakconcepts/landingpage-kit'
import defaultPages from './content/default/defaultPages'
import defaultSettings from './content/default/defaultSettings'

// Pages
import home from './content/pages/home'
import start from './content/pages/start'
import wertermittlung from './content/pages/wertermittlung'
import terminbuchen from './content/pages/terminbuchen'

// Google Ads Pages
import googleAdsWertermittlungHaus from './content/ads/wertermittlung-haus'
import googleAdsHausverkaufErbe from './content/ads/hausverkauf-erbe'
import googleAdsDoppelhaus from './content/ads/doppelhaus'
import googleAdsEigentumswohnung from './content/ads/eigentumswohnung'
import googleAdsGrundstueck from './content/ads/grundstueck'
import googleAdsHausScheidung from './content/ads/haus-scheidung'
import googleAdsHaus from './content/ads/haus'
import googleAdsWohnung from './content/ads/wohnung'

const content: Content = {
  ...defaultSettings,
  pages: [
    {
      ...home
    },
    {
      ...start
    },
    {
      ...wertermittlung
    },
    {
      ...terminbuchen
    },
    // Google ads
    {
      ...googleAdsWertermittlungHaus
    },
    {
      ...googleAdsHausverkaufErbe
    },
    {
      ...googleAdsDoppelhaus
    },
    {
      ...googleAdsEigentumswohnung
    },
    {
      ...googleAdsGrundstueck
    },
    {
      ...googleAdsHausScheidung
    },
    {
      ...googleAdsHaus
    },
    {
      ...googleAdsWohnung
    },
    ...defaultPages
  ]
}
export default content
