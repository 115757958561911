import {
  QuestionnaireInterface,
  QuestionTypes
} from '@peakconcepts/landingpage-kit'

import colors from '../../content/default/colors'

const iconCss = {
  color: '#000'
}
const data: QuestionnaireInterface = {
  initStep: 1,
  mainColor: colors.questionnairyMain,
  sectionConfig: {
    container: false,
    padding: { desktop: '100px 0', tablet: '60px 0', mobile: '0' }
  },
  cardConfig: {
    defaultCss: {
      backgroundColor: 'rgb(238, 238, 238)'
    }
  },
  advantages: [
    {
      iconConfig: {
        defaultCss: iconCss
      },
      descriptionConfig: {
        text: 'Beratung durch Experten',
        defaultCss: iconCss
      }
    },
    {
      iconConfig: {
        defaultCss: iconCss
      },
      descriptionConfig: {
        text: 'Persönlicher Ansprechpartner',
        defaultCss: iconCss
      }
    },
    {
      iconConfig: {
        defaultCss: iconCss
      },
      descriptionConfig: {
        text: 'Kostenlose Wertermittlung vor Ort',
        defaultCss: iconCss
      }
    }
  ],
  buttons: {
    finish: {
      text: 'Jetzt Wertermittlung sichern',
      subline: '100% kostenlos & unverbindlich'
    }
  },
  questions: [
    {
      step: 1,
      type: QuestionTypes.select,
      eventName: 'TYPE',
      asListOnMobile: true,
      cardConfig: {
        titleConfig: {
          text: 'Welche Immobilie möchten Sie verkaufen?'
        }
      },
      answers: [
        {
          nextStep: 2,
          descriptionConfig: {
            text: 'Wohnung'
          },
          image: {
            src: '/icons/wohnung.svg'
          }
        },
        {
          nextStep: 8,
          descriptionConfig: { text: 'Haus' },
          image: {
            src: '/icons/haus.svg'
          }
        },
        {
          nextStep: 16,
          descriptionConfig: { text: 'Gewerbe' },
          image: {
            src: '/icons/gewerbe.svg'
          }
        },
        {
          nextStep: 22,
          descriptionConfig: {
            text: 'Grundstück'
          },
          image: {
            src: '/icons/grundstueck.svg'
          }
        }
      ]
    },
    {
      step: 2,
      asListOnMobile: true,
      nextStep: 3,
      type: QuestionTypes.select,
      eventName: 'FLAT',
      cardConfig: {
        titleConfig: {
          text: 'Bitte wählen Sie die Art Ihrer Wohnung?'
        }
      },
      answers: [
        {
          descriptionConfig: {
            text: 'Untergeschoss'
          },
          image: {
            src: '/icons/untergeschoss.svg'
          }
        },
        {
          descriptionConfig: { text: 'Erdgeschoss' },
          image: {
            src: '/icons/erdgeschosswohnung.svg'
          }
        },
        {
          descriptionConfig: { text: 'Etagenwohnung' },
          image: {
            src: '/icons/etagenwohnung.svg'
          }
        },
        {
          descriptionConfig: { text: 'Dachgeschoss' },
          image: {
            src: '/icons/dachgeschoss.svg'
          }
        }
      ]
    },
    {
      step: 3,
      nextStep: 4,
      type: QuestionTypes.range,
      eventName: 'FLAT_SPACE',
      rangeConfig: {
        min: 30,
        max: 300,
        default: 80,
        label: ' m²'
      },
      cardConfig: {
        titleConfig: {
          text: 'Wie groß ist die Wohnfläche der Wohnung?'
        }
      },
      textConfig: {
        label: 'Alternativ eintippen'
      },
      image: {
        src: '/icons/flaeche.svg'
      }
    },
    {
      step: 4,
      nextStep: 5,
      type: QuestionTypes.range,
      eventName: 'FLAT_ROOM',
      rangeConfig: {
        min: 1,
        max: 10,
        default: 3,
        step: 0.5
      },
      cardConfig: {
        titleConfig: {
          text: 'Wie viele Zimmer hat die Wohnung?'
        }
      },
      textConfig: {
        label: 'Alternativ eintippen'
      },
      image: {
        src: '/icons/zimmer.svg'
      }
    },
    {
      step: 5,
      nextStep: 6,
      type: QuestionTypes.range,
      eventName: 'FLAT_YEAR',
      rangeConfig: {
        min: 1900,
        max: new Date().getFullYear(),
        default: 1980
      },
      cardConfig: {
        titleConfig: {
          text: 'Wann wurde das Haus gebaut?'
        }
      },
      textConfig: {
        label: 'Alternativ eintippen'
      },
      image: {
        src: '/icons/gebaut.svg'
      }
    },
    {
      step: 6,
      nextStep: 7,
      type: QuestionTypes.select,
      eventName: 'FLAT_SALE',
      cardConfig: {
        titleConfig: {
          text: 'Wann möchten Sie Ihre Immobilie verkaufen?'
        }
      },
      answers: [
        {
          descriptionConfig: {
            text: 'Schnellstmöglich'
          },
          image: {
            src: '/icons/schnellstmoeglich.svg'
          }
        },
        {
          descriptionConfig: { text: '1 - 6 Monate' },
          image: {
            src: '/icons/1-6-monate.svg'
          }
        },
        {
          descriptionConfig: { text: '6 - 12 Monate' },
          image: {
            src: '/icons/6-12-monate.svg'
          }
        },
        {
          descriptionConfig: { text: '12 Monate oder später' },
          image: {
            src: '/icons/12-monate.svg'
          }
        }
      ]
    },
    {
      step: 7,
      nextStep: 26,
      type: QuestionTypes.text,
      eventName: 'FLAT_REGION',
      cardConfig: {
        titleConfig: {
          text: 'In welcher Region befindet sich die Immobilie?'
        }
      },
      textConfig: {
        label: 'Postleitzahl',
        description:
          'Ihre Postleitzahl wird benötigt, um den Wert Ihrer Immobilie besser ermitteln zu können.',
        placeholder: 'z.B 50667',
        regex: /[0-9]{5}/g,
        maxLength: 5,
        replaceWithRegexOnInput: /[^0-9]+/g
      },
      image: {
        src: '/icons/map.svg',
        width: {
          desktop: '300px',
          tablet: '300px',
          mobile: '150px'
        }
      }
    },
    {
      step: 8,
      nextStep: 9,
      type: QuestionTypes.select,
      eventName: 'HOUSE',
      asListOnMobile: true,
      cardConfig: {
        titleConfig: {
          text: 'Bitte wählen Sie die Art Ihres Hauses'
        }
      },
      answers: [
        {
          descriptionConfig: {
            text: 'Einfamilienhaus'
          },
          image: {
            src: '/icons/einfamilienhaus.svg'
          }
        },
        {
          descriptionConfig: { text: 'Reihenhaus' },
          image: {
            src: '/icons/reihenhaus.svg'
          }
        },
        {
          descriptionConfig: { text: 'Doppelhaushälfte' },
          image: {
            src: '/icons/doppelhaushaelfte.svg'
          }
        },
        {
          descriptionConfig: { text: 'Mehrfamilienhaus' },
          image: {
            src: '/icons/mehrfamilienhaus.svg'
          }
        }
      ]
    },
    {
      step: 9,
      nextStep: 10,
      type: QuestionTypes.range,
      eventName: 'HOUSE_SPACE',
      rangeConfig: {
        min: 30,
        max: 300,
        default: 80,
        label: ' m²'
      },
      cardConfig: {
        titleConfig: {
          text: 'Wie groß ist die Wohnfläche des Hauses?'
        }
      },
      textConfig: {
        label: 'Alternativ eintippen'
      },
      image: {
        src: '/icons/flaeche.svg'
      }
    },
    {
      step: 10,
      nextStep: 11,
      type: QuestionTypes.range,
      eventName: 'HOUSE_PROPERTY',
      rangeConfig: {
        min: 200,
        max: 2500,
        default: 300,
        label: ' m²'
      },
      cardConfig: {
        titleConfig: {
          text: 'Wie groß ist das Grundstück des Hauses?'
        }
      },
      textConfig: {
        label: 'Alternativ eintippen'
      },
      image: {
        src: '/icons/hausgrundstueck.svg'
      }
    },
    {
      step: 11,
      nextStep: 12,
      type: QuestionTypes.select,
      eventName: 'HOUSE_FLOORS',
      cardConfig: {
        titleConfig: {
          text: 'Wie viele Etagen hat das Haus?'
        }
      },
      answers: [
        {
          descriptionConfig: {
            text: '1 Etage'
          },
          image: {
            src: '/icons/etage1.svg'
          }
        },
        {
          descriptionConfig: { text: '2 Etagen' },
          image: {
            src: '/icons/etage2.svg'
          }
        },
        {
          descriptionConfig: { text: '3 Etagen' },
          image: {
            src: '/icons/etage3.svg'
          }
        },
        {
          descriptionConfig: { text: '4+ Etagen' },
          image: {
            src: '/icons/etage4+.svg'
          }
        }
      ]
    },
    {
      step: 12,
      nextStep: 13,
      type: QuestionTypes.range,
      eventName: 'HOUSE_ROOMS',
      rangeConfig: {
        min: 1,
        max: 15,
        default: 4,
        step: 0.5
      },
      cardConfig: {
        titleConfig: {
          text: 'Wie viele Zimmer hat das Haus?'
        }
      },
      textConfig: {
        label: 'Alternativ eintippen'
      },
      image: {
        src: '/icons/zimmer.svg'
      }
    },
    {
      step: 13,
      nextStep: 14,
      type: QuestionTypes.range,
      eventName: 'HOUSE_YEAR',
      rangeConfig: {
        min: 1900,
        max: new Date().getFullYear(),
        default: 1960
      },
      cardConfig: {
        titleConfig: {
          text: 'Wann wurde das Haus gebaut?'
        }
      },
      textConfig: {
        label: 'Alternativ eintippen'
      },
      image: {
        src: '/icons/gebaut.svg'
      }
    },
    {
      step: 14,
      nextStep: 15,
      type: QuestionTypes.select,
      eventName: 'HOUSE_SALE',
      cardConfig: {
        titleConfig: {
          text: 'Wann möchten Sie Ihr Haus verkaufen?'
        }
      },
      answers: [
        {
          descriptionConfig: {
            text: 'Schnellstmöglich'
          },
          image: {
            src: '/icons/schnellstmoeglich.svg'
          }
        },
        {
          descriptionConfig: { text: '1 - 6 Monate' },
          image: {
            src: '/icons/1-6-monate.svg'
          }
        },
        {
          descriptionConfig: { text: '6 - 12 Monate' },
          image: {
            src: '/icons/6-12-monate.svg'
          }
        },
        {
          descriptionConfig: { text: '12 Monate oder später' },
          image: {
            src: '/icons/12-monate.svg'
          }
        }
      ]
    },
    {
      step: 15,
      nextStep: 26,
      type: QuestionTypes.text,
      eventName: 'HOUSE_REGION',
      cardConfig: {
        titleConfig: {
          text: 'In welcher Region befindet sich die Immobilie?'
        }
      },
      textConfig: {
        label: 'Postleitzahl',
        description:
          'Ihre Postleitzahl wird benötigt, um den Wert Ihrer Immobilie besser ermitteln zu können.',
        placeholder: 'z.B 50667',
        regex: /[0-9]{5}/g,
        maxLength: 5,
        replaceWithRegexOnInput: /[^0-9]+/g
      },
      image: {
        src: '/icons/map.svg',
        width: {
          desktop: '300px',
          tablet: '300px',
          mobile: '150px'
        }
      }
    },
    {
      step: 16,
      nextStep: 17,
      type: QuestionTypes.range,
      eventName: 'INDUSTRY_SPACE',
      rangeConfig: {
        min: 100,
        max: 700,
        default: 300,
        label: ' m²'
      },
      cardConfig: {
        titleConfig: {
          text: 'Wie groß ist die Gewerbefläche der Immobilie?'
        }
      },
      textConfig: {
        label: 'Alternativ eintippen'
      },
      image: {
        src: '/icons/flaeche.svg'
      }
    },
    {
      step: 17,
      nextStep: 18,
      type: QuestionTypes.range,
      eventName: 'INDUSTRY_PROPERTY',
      rangeConfig: {
        min: 200,
        max: 2500,
        default: 500,
        label: ' m²'
      },
      cardConfig: {
        titleConfig: {
          text: 'Welche Fläche hat das Grundstück?'
        }
      },
      textConfig: {
        label: 'Alternativ eintippen'
      },
      image: {
        src: '/icons/grundstueck.svg'
      }
    },
    {
      step: 18,
      nextStep: 19,
      type: QuestionTypes.select,
      eventName: 'INDUSTRY_FLOORS',
      cardConfig: {
        titleConfig: {
          text: 'Wie viele Etagen hat die Immobilie?'
        }
      },
      answers: [
        {
          descriptionConfig: {
            text: '1 Etage'
          },
          image: {
            src: '/icons/etage1.svg'
          }
        },
        {
          descriptionConfig: { text: '2 Etagen' },
          image: {
            src: '/icons/etage2.svg'
          }
        },
        {
          descriptionConfig: { text: '3 Etagen' },
          image: {
            src: '/icons/etage3.svg'
          }
        },
        {
          descriptionConfig: { text: '4+ Etagen' },
          image: {
            src: '/icons/etage4+.svg'
          }
        }
      ]
    },
    {
      step: 19,
      nextStep: 20,
      type: QuestionTypes.range,
      eventName: 'INDUSTRY_YEAR',
      rangeConfig: {
        min: 1900,
        max: new Date().getFullYear(),
        default: 1960
      },
      cardConfig: {
        titleConfig: {
          text: 'Wann wurde die Immobilie gebaut?'
        }
      },
      textConfig: {
        label: 'Alternativ eintippen'
      },
      image: {
        src: '/icons/gebaut.svg'
      }
    },
    {
      step: 20,
      nextStep: 21,
      type: QuestionTypes.select,
      eventName: 'INDUSTRY_SALE',
      cardConfig: {
        titleConfig: {
          text: 'Wann möchten Sie Ihre Immobilie verkaufen?'
        }
      },
      answers: [
        {
          descriptionConfig: {
            text: 'Schnellstmöglich'
          },
          image: {
            src: '/icons/schnellstmoeglich.svg'
          }
        },
        {
          descriptionConfig: { text: '1 - 6 Monate' },
          image: {
            src: '/icons/1-6-monate.svg'
          }
        },
        {
          descriptionConfig: { text: '6 - 12 Monate' },
          image: {
            src: '/icons/6-12-monate.svg'
          }
        },
        {
          descriptionConfig: { text: '12 Monate oder später' },
          image: {
            src: '/icons/12-monate.svg'
          }
        }
      ]
    },
    {
      step: 21,
      nextStep: 26,
      type: QuestionTypes.text,
      eventName: 'INDUSTRY_REGION',
      cardConfig: {
        titleConfig: {
          text: 'In welcher Region befindet sich die Immobilie?'
        }
      },
      textConfig: {
        label: 'Postleitzahl',
        description:
          'Ihre Postleitzahl wird benötigt, um den Wert Ihrer Immobilie besser ermitteln zu können.',
        placeholder: 'z.B 50667',
        regex: /[0-9]{5}/g,

        maxLength: 5,
        replaceWithRegexOnInput: /[^0-9]+/g
      },
      image: {
        src: '/icons/map.svg',
        width: {
          desktop: '300px',
          tablet: '300px',
          mobile: '150px'
        }
      }
    },
    {
      step: 22,
      nextStep: 23,
      type: QuestionTypes.range,
      eventName: 'LAND_PROPERTY',
      rangeConfig: {
        min: 200,
        max: 2500,
        default: 500,
        label: ' m²'
      },
      cardConfig: {
        titleConfig: {
          text: 'Welche Fläche hat das Grundstück?'
        }
      },
      textConfig: {
        label: 'Alternativ eintippen'
      },
      image: {
        src: '/icons/quadratmeter.svg'
      }
    },
    {
      step: 23,
      nextStep: 24,
      type: QuestionTypes.select,
      eventName: 'LAND_TYPE',
      cardConfig: {
        titleConfig: {
          text: 'Um was für ein Grundstücksart handelt es sich?'
        }
      },
      answers: [
        {
          descriptionConfig: {
            text: 'Bauland'
          },
          image: {
            src: '/icons/bauland.svg'
          }
        },
        {
          descriptionConfig: { text: 'Ackerland' },
          image: {
            src: '/icons/ackerland.svg'
          }
        },
        {
          descriptionConfig: { text: 'Wald / Grünland' },
          image: {
            src: '/icons/wald.svg'
          }
        },
        {
          descriptionConfig: { text: 'Sonstiges' },
          image: {
            src: '/icons/sonstiges.svg'
          }
        }
      ]
    },
    {
      step: 24,
      nextStep: 25,
      type: QuestionTypes.select,
      eventName: 'LAND_SALE',
      cardConfig: {
        titleConfig: {
          text: 'Wann möchten Sie das Grundstück verkaufen?'
        }
      },
      answers: [
        {
          descriptionConfig: {
            text: 'Schnellstmöglich'
          },
          image: {
            src: '/icons/schnellstmoeglich.svg'
          }
        },
        {
          descriptionConfig: { text: '1 - 6 Monate' },
          image: {
            src: '/icons/1-6-monate.svg'
          }
        },
        {
          descriptionConfig: { text: '6 - 12 Monate' },
          image: {
            src: '/icons/6-12-monate.svg'
          }
        },
        {
          descriptionConfig: { text: '12 Monate oder später' },
          image: {
            src: '/icons/12-monate.svg'
          }
        }
      ]
    },
    {
      step: 25,
      nextStep: 26,
      type: QuestionTypes.text,
      eventName: 'LAND_REGION',
      cardConfig: {
        titleConfig: {
          text: 'In welcher Region befindet sich das Grundstück?'
        }
      },
      textConfig: {
        label: 'Postleitzahl',
        description:
          'Ihre Postleitzahl wird benötigt, um den Wert Ihrer Immobilie besser ermitteln zu können.',
        placeholder: 'z.B 50667',
        regex: /[0-9]{5}/g,
        maxLength: 5,
        replaceWithRegexOnInput: /[^0-9]+/g
      },
      image: {
        src: '/icons/map.svg',
        width: {
          desktop: '300px',
          tablet: '300px',
          mobile: '150px'
        }
      }
    },
    {
      step: 26,
      nextStep: 27,
      type: QuestionTypes.finish,
      eventName: 'CONTACT_DATA',
      config: {
        title:
          '<h4 style="text-align:center;color:#fff;font-size:1rem;">Letzter Schritt. Dann war\'s das schon!</h4>',
        description:
          '<b>Wer wird die kostenlose Wertermittlung in Anspruch nehmen?</b>',
        color: '#37761d'
      },
      image: {
        width: {
          desktop: '80%',
          tablet: '80%',
          mobile: '80%'
        },
        src: '/icons/calculation/finish.png'
      }
    },
    {
      step: 27,
      type: QuestionTypes.formSent,
      eventName: 'FINISH',
      config: {
        title:
          '<h4 style="text-align:center;color:#fff;font-size:1rem;">Vielen Dank. Wir werden Sie in Kürze kontaktieren</h4>',
        description:
          '<p><strong>Guten Tag {firstname} {lastname},</strong><br/><br/>vielen Dank für Ihre Anfrage zur kostenlosen Wertermittlung Ihrer Immobilie. Ich werde mich in Kürze mit Ihnen telefonisch in Verbindung setzen, um einen Termin für die kostenlose Wertermittlung bei Ihnen vor Ort zu vereinbaren.<br/><br/>Mit freundlichen Grüßen<br /><strong>Axel Steiner</strong></p>',
        color: '#37761d',
        contact:
          '<p style="margin-top:20px;margin-bottom:0px;"><b>Axel Steiner</b></p><small style="color:#aaa;font-size:12px;">Experte Immobilenverkauf</small>'
      },
      image: {
        src: '/images/contact-person.png'
      }
    }
  ]
}

export default data
